import React from "react"
import { WhiteButton } from "../components/buttons"

const ThanksBakery = () => {
  return (
    <div className="h-screen flex items-center justify-center">
      <div className="grid gap-2 m-4 p-8 lg:p-16 rounded  shadow-lg">
        <p className="subtitle">Thank you for your purchase.</p>
        <p>You will receive a text message with the confirmation.</p>
        <div className="w-full flex justify-end pt-4 pr-2">
          <WhiteButton to="/" text="Got it" />
        </div>
      </div>
    </div>
  )
}

export default ThanksBakery
